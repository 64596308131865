import React, { useEffect, MouseEvent, useContext } from "react";
import * as Sentry from "@sentry/react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

import { PlanType } from "../../../models/plans";
import { PageSettingItem } from "../../../models/pageSettings";
import { IconJumbotron } from "../../common/icons";
import { useMutation } from "@apollo/client";
import { PAYMUTATION } from "./queries";
import {
  AnalyticsContext,
  EcommerceData,
} from "../../../context/analytics-context";
import { LoaderBar } from "../../../middleware/loaders";
import { PlanItem, UserItem } from "../../../models/user";
import { PromoCodeType } from "../../../models/promocodes";

type PaymentProps = {
  selectedPlan: PlanType;
  paypal_setting?: PageSettingItem;
  handlePageView: (ev: MouseEvent<HTMLButtonElement>) => void;
  promo_code?: PromoCodeType;
};

const currency = "USD";
const style: Paypal = { layout: "vertical", label: "pay" };

const Payment: React.FC<PaymentProps> = ({
  selectedPlan,
  paypal_setting,
  handlePageView,
  promo_code,
}) => {
  const paypal_settings = JSON.parse(
    paypal_setting?.description || "[]"
  ) as PaypalSettingsType[];
  const paypal = paypal_settings[0];
  const price_amount = selectedPlan.loyalty_price || selectedPlan.price_amount;

  return (
    <>
      <div className="col-md-6">
        {paypal_setting ? (
          <PayPalScriptProvider
            options={{
              "client-id":
                !paypal.mode || paypal.mode === "production"
                  ? paypal.production_client_id
                  : paypal.sandbox_client_id,
              components: "buttons",
              currency,
            }}
          >
            <ButtonWrapper
              currency={currency}
              showSpinner={false}
              selectedPlan={selectedPlan}
              price_amount={price_amount}
              promo_code={promo_code}
            />
          </PayPalScriptProvider>
        ) : (
          <IconJumbotron
            icon="fa-solid fa-bug-slash"
            txt="Something went wrong"
            help_text="please refresh and try again"
          />
        )}
      </div>
      <div className="col-md-4">
        <div className="card round">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h6>Order Details</h6>{" "}
              <button
                onClick={handlePageView}
                data-view="plans"
                className="btn text-primary text-underlined btn_sm"
              >
                Change Plan
              </button>
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td>Plan</td>
                  <td className="text-right">{selectedPlan.name}</td>
                </tr>
                {selectedPlan.regular_price ? (
                  <tr>
                    <td>Regular Price</td>
                    <td className="text-right">
                      <del>${selectedPlan.regular_price}</del>
                    </td>
                  </tr>
                ) : null}
                {selectedPlan.loyalty_price ? (
                  <tr>
                    <td>New User Price</td>
                    <td className="text-right">
                      <del>${selectedPlan.price_amount}</del>
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <td>Total </td>
                  <td className="text-right">
                    <h4 className="m-0">
                      <b>${price_amount}</b>
                    </h4>
                    {selectedPlan.loyalty_price ? (
                      <small className="badge text-danger pr-0">
                        <i className="far fa-star" aria-hidden="true"></i>{" "}
                        Loyalty Pricing
                      </small>
                    ) : null}
                    {selectedPlan.has_promo ? (
                      <small className="badge text-danger pr-0">
                        <i className="fal fa-tags"></i> Promo Code Applied
                      </small>
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="mt-3 p-2">
          <h5>Prefer to pay by purchase order?</h5>
          <p>
            {" "}
            You can purchase or renew your membership at Teacher's Discovery®.
            <a
              href="https://www.teachersdiscovery.com/product/flangoo-spanish-digital-readers-subscription/spanish"
              className="btn btn-danger mt-3 mb-4 btn-block text-white"
            >
              Shop at Teacher's Discovery
            </a>
            <br />
            Your Flangoo account does not auto-renew.
          </p>
        </div>
      </div>
    </>
  );
};

type ButtonWrapperProps = {
  currency: string;
  showSpinner: boolean;
  selectedPlan: PlanType;
  isBuyNow?: boolean;
  price_amount: number;
  promo_code?: PromoCodeType;
};
export const ButtonWrapper: React.FC<ButtonWrapperProps> = ({
  currency,
  showSpinner,
  selectedPlan,
  price_amount,
  promo_code,
  isBuyNow = false,
}) => {
  const analyticsContext = useContext(AnalyticsContext);
  const user: UserItem = JSON.parse(localStorage.getItem("user") || "{}");
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const [makePayment, { data, error: payment_error, called }] = useMutation<{
    paymentSuccess: PlanItem;
  }>(PAYMUTATION);
  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: currency,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, showSpinner]);

  useEffect(() => {
    if (payment_error) {
      console.log("payment.tsx:213 | error", payment_error);
      Sentry.captureMessage(
        "Payment Mutation Error Hook",
        Sentry.Severity.Fatal
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment_error]);

  useEffect(() => {
    if (data && data.paymentSuccess) {
      ///save event to google analytics
      const event = {
        category: "User",
        action: isBuyNow ? "BuyNowSubscription" : "Subscription",
        label: `${selectedPlan.name}`,
        value: price_amount,
      };
      analyticsContext.event(event);
      const set_user = { ...user };
      set_user.plan = data.paymentSuccess;
      localStorage.setItem("user", JSON.stringify(set_user));
      if (isBuyNow) {
        window.location.href = "/thankyou";
      } else {
        window.location.href = "/youraccount";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {showSpinner || isPending ? <LoaderBar /> : null}
      {called ? (
        payment_error ? (
          <IconJumbotron
            icon="far fa-exclamation-circle text-danger"
            classes="my-3 py-3"
            txt="Oh oh!"
            help_text="Something went wrong. Please contact customer support."
            cta={{
              txt: "Contact Support",
              link: "/help",
              classes: "btn btn-danger text-white",
            }}
          />
        ) : (
          <IconJumbotron
            icon="far fa-check-circle text-success"
            classes="my-3 py-3"
            txt="Payment Successful"
            help_text="Please don't close or refresh the page. Your subcriptions is beeing updated."
          />
        )
      ) : (
        <PayPalButtons
          style={style}
          disabled={false}
          forceReRender={[price_amount, currency, style]}
          fundingSource={undefined}
          createOrder={async (_, actions) => {
            const orderId = await actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: currency,
                    value: price_amount.toLocaleString(),
                  },
                  custom_id: user.email, // Include user's email or other custom data here
                  invoice_id: `FLANGOO-${user.id}-${Date.now()}`, // Set an invoice ID for tracking
                },
              ],
              application_context: {
                shipping_preference: "NO_SHIPPING",
              },
            });
            return orderId;
          }}
          onError={(err) => {
            console.log("payment.tsx:201 | err", err);
            Sentry.captureMessage(
              "Paypal Payment Error",
              Sentry.Severity.Fatal
            );
          }}
          onApprove={async (data, actions) => {
            try {
              const details = await actions!.order!.capture();
              console.log("payment.tsx:205 | actions", actions);
              console.log("payment.tsx:206 | details", details);

              // Get the Transaction ID safely
              const transactionId =
                (details?.purchase_units?.[0]?.payments?.captures?.[0]
                  ?.id as string) ?? "UNKNOWN_TRANSACTION_ID";
              console.log("Transaction ID: ", transactionId);

              try {
                makePayment({
                  variables: {
                    pay_id: transactionId, // Use transactionId here
                    plan_id: selectedPlan.id,
                    method: "paypal",
                    promo_code: promo_code?.code || "",
                  },
                });
                const ecommerce_data: EcommerceData = {
                  id: transactionId, // Use transactionId here
                  sku: selectedPlan.sku,
                  subscription: selectedPlan.name,
                  category: "Subscriptions",
                  name: "addSubscription",
                  revenue: price_amount.toLocaleString(),
                };
                analyticsContext.ecommerce(ecommerce_data);
              } catch (error) {
                ////log error to sentry
                console.log("payment.tsx:232 | error", error);
                Sentry.captureMessage(
                  "Payment Mutation Error",
                  Sentry.Severity.Fatal
                );
                return;
              }
            } catch (error) {
              ////log error to sentry
              console.log("payment.tsx:241 | error", error);
              Sentry.captureMessage(
                "Paypal Payment Error",
                Sentry.Severity.Fatal
              );
              return;
            }
          }}
        />
      )}
    </>
  );
};

export default Payment;
